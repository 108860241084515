import { makeStyles } from '@material-ui/styles';
import { getButtonSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React from 'react';
import SimpleButton from './simple-button';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0 !important',
        '& .btn': {
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 30px 12px 30px',
            fontSize: 14,
            background: '#212121',
            fontWeight: 500,
            borderRadius: '0px',
            letterSpacing: 0.5,
            textDecoration: 'none',
            pointerEvents: 'all',
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400,
                letterSpacing: 0
            },
            '&.anni-dotd, .anni-dotd &': {
                backgroundClip: 'text',
				'-webkit-text-fill-color': 'transparent',
				'-webkit-background-clip': 'text',
				backgroundColor: '#B99354 !important',
				backgroundImage: 'linear-gradient(180deg, #FBD7A2, #B99354) !important',
				borderWidth: '1px !important',
                borderStyle: 'solid  !important',
                borderColor: '#B99354  !important',
				borderImageSlice: '1  !important',
				borderImageSource: 'linear-gradient(180deg, #FBD7A2, #B99354) !important',
            },
            '.gw-section &, .gw-section &:hover': {
                backgroundColor: '#ffffff !important',
                color: "#000 !important"
            }
        },
    },
}), { name: 'CmsButton' });

export default function CmsButton({ slots, block, slotName = 'button', isInBanner = false }) {
    const button = getCMSSlot(slotName, slots);
    const ButtonSettings = getButtonSettings(block, button.config);
    const classes = useStyles();

    if(button.config.title.value === '') {
        return '';
    }

    return (
        <div
            style={{
                ...ButtonSettings.styles.wrapper,
                marginBottom: !isInBanner ? '60px' : '0px'
            }}
            className={classes.wrapper}
        >
            <SimpleButton config={button.config} styles={ButtonSettings.styles.button}
                          buttonClass={[ButtonSettings.class, isInBanner ? 'isBanner' : ''].join(' ')}/>
        </div>
    );
}
