import { makeStyles } from "@material-ui/styles";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import { useTranslation } from "tools/i18n";
import { Button, CircularProgress, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Image from "next/image";
import React, { useContext, useEffect, useState } from "react";
import EmailValidator from "email-validator";
import { SessionContext } from "../../../providers/session";
import { TrackingContext } from "../../../providers/tracking";
import theme from "../../../theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextContent from "../text/text-content";
import ChevronRight from "../../../../public/images/icons/chevron_right_light.svg";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr'
    },
    image: {
        gridColumn: '1/2',
        gridRow: '1/2',
        backgroundColor: 'black',
        '& img': {
            opacity: 0.65,
            [theme.breakpoints.down('sm')]: {
                objectFit: 'cover'
            }
        },
        '& > div': {
            height: '100%'
        }
    },
    contentWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gridColumn: '1/2',
        gridRow: '1/2',
        maxWidth: theme.maxWidth,
        width: '100%',
        margin: '0 auto',
        padding: '20px 60px',
        [theme.breakpoints.down('sm')]: {
            padding: 30
        }
    },
    content: {},
    contentStyling: {
        marginLeft: 0,
        '& > div': {
            color: 'white !important'
        },
        '& li': {

        }
    },
    title: {
        margin: 0,
        fontSize: 28,
        fontWeight: 400,
        color: 'white',
        fontFamily: 'Chronicle, "Open Sans", sans-serif'
    },
    subtitle: {
        margin: '10px 0 0',
        color: 'white'
    },
    actions: {
        position: 'relative',
        marginTop: 30,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&.showCountrySelector': {
                flexWrap: 'wrap'
            }
        }
    },
    inputWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    newsletterSelectWrapper: {
        marginRight: 10,
        fontSize: 14,
        backgroundColor: 'white'
    },
    newsletterSelect: {
        padding: '15.5px 14px',
    },
    newsletterInputWrapper: {
        [theme.breakpoints.down('xs')]: {
            '.showCountrySelector &': {
                flexGrow: 1
            }
        }
    },
    newsletterInput: {
        border: '1px solid white',
        borderRadius: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            border: 'none',
            borderBottom: '1px solid white',
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 200
        }
    },
    newsletterInputRoot: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 250
        }
    },
    newsletterInputBaseRoot: {
        backgroundColor: 'transparent'
    },
    newsletterSignup: {
        marginLeft: 10,
        padding: '6px 34px',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0',
        textTransform: 'none',
        textAlign: 'left',
        borderRadius: 0,
        color: 'white',
        backgroundColor: 'rgba(255,255,255,0.5)',
        border: '1px solid white',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.5)'
        },
        '.showCountrySelector &': {
        },
        '.light &': {
            backgroundColor: '#B99354',
            backgroundImage: 'linear-gradient(180deg, #FBD7A2, #B99354)',
            color: '#16243C'
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: 0,
            marginRight: -18,
            padding: 6,
            backgroundColor: 'transparent',
            border: 'none',
            '& svg': {
                fill: 'white'
            }
        }
    },
    newsletterInputLabel: {
        fontSize: 14,
        color: 'white !important',
        [theme.breakpoints.down('sm')]: {
            marginLeft: -12
        }
    }
}), { name: 'NewsletterSignup' });

export default function FancyNewsletterSignup({ slots, block }) {
    const classes = useStyles();
    const CMSSettings = getCMSSettings(block);
    const slot = getCMSSlot('fancyNewsletterSignup', slots);
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation(['popup', 'errors']);
    const { token, salesChannel, config, countries, actions: sessionActions } = useContext(SessionContext);
    const { actions: trackingActions } = useContext(TrackingContext);
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showCountrySelector, setShowCountrySelector] = useState(false);
    const [country, setCountry] = useState(false);
    const salesChannelId = salesChannel.id;
    const countryId = salesChannel.countryId;

    useEffect(() => {
        if (token && config.language.locale === 'en') {
            getCountries();
        }
    }, [token]);

    useEffect(() => {
        if (countries && countries.length > 1) {
            setShowCountrySelector(true);
            setCountry(getInitialSalesChannelCountry());
        }
    }, [countries]);

    function getImage() {
        if(isDesktop) {
            return {
                src: slot.data?.desktopImage?.url,
                height: slot.data?.desktopImage?.metaData.height,
                width: slot.data?.desktopImage?.metaData.width,
                layout: 'responsive'
            }
        } else {
            return {
                src: slot.data?.mobileImage?.url,
                height: slot.data?.mobileImage?.metaData.height,
                width: slot.data?.mobileImage?.metaData.width
            }
        }
    }

    async function getCountries() {
        await sessionActions.getCountries();
    }

    function getInitialSalesChannelCountry() {
        let defaultCountry = countries.filter((country) => {
            return country.id === countryId;
        });

        return defaultCountry.length > 0 ? defaultCountry[0].iso : countries[0].iso;
    }

    function validateEmail(email) {
        return EmailValidator.validate(email);
    }

    function getDiscoveryKey() {
        let discoveryKey = localStorage.getItem('apptus.session') ? JSON.parse(localStorage.getItem('apptus.session')).customerKey : false;
        return discoveryKey;
    }

    async function newsletterSubmit() {
        if (!validateEmail(newsletterEmail)) {
            return;
        }

        let subscriberCountry = country ? country : config?.language?.languageCode.split('-')[1];

        try {
            setLoading(true);

            let body = {
                email: newsletterEmail,
                country: subscriberCountry,
                store: salesChannelId,
                source: 'fancy',
            }

            const discoveryKey = getDiscoveryKey();
            if (discoveryKey) {
                body.discovery_key = discoveryKey;
            }

            let response = await fetch('/api/voyado/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            let registrationData = await response.json();

            if (registrationData.status === 'error') {
                if (registrationData.message.indexOf('Member exists') >= 0) {
                    setError(t('errors:signup-already-exists'));
                } else if (registrationData.message.indexOf('Request is invalid') >= 0) {
                    setError(t('errors:signup-request-invalid'));
                } else {
                    setError(registrationData.message);
                }
            } else {
                setError(false);
                setNewsletterEmail('');
                setSuccess(true);

                trackingActions.click({
                    type: 'footer_newsletter',
                    text: t('popup:intro-subscribe'),
                    identifier: 'signup_success'
                });
            }
            setLoading(false);
        } catch (e) {
            setError(t('errors:signup-general-error'));

            setLoading(false);
        }
    }

    return (
        <div className={[classes.wrapper, CMSSettings.class].join(' ')} style={{
            ...CMSSettings.styles,
        }}>
            <div className={classes.image}>
                <Image {...getImage()} unoptimized/>
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <h3 className={classes.title}>{slot.translated?.config?.title?.value}</h3>
                    <div className={classes.subtitle}><TextContent isVariant={true} className={classes.contentStyling} content={slot.translated?.config?.text?.value}/></div>

                    {error ? (
                        <Grid item>
                            <Alert severity="error" style={{ marginBottom: 10 }}>
                                <span dangerouslySetInnerHTML={{ __html: error }}/>
                            </Alert>
                        </Grid>
                    ) : (
                        ''
                    )}

                    <div className={[classes.actions, 'showCountrySelector'].join(' ')}>
                        <div className={classes.inputWrapper}>
                            {showCountrySelector ?
                                <Select
                                    value={country}
                                    onChange={(event) => setCountry(event.target.value)}
                                    classes={{
                                        select: classes.newsletterSelect
                                    }}
                                    className={classes.newsletterSelectWrapper}
                                    variant={'outlined'}
                                    size={'small'}
                                    autoWidth={true}>
                                    {countries.sort((a, b) => {
                                        if (a.iso < b.iso) {
                                            return -1;
                                        }

                                        if (a.iso > b.iso) {
                                            return 1;
                                        }
                                    }).map((country) => <MenuItem value={country.iso}>{country.translated?.name}</MenuItem>)}
                                </Select>
                                : ''}

                            <TextField
                                type="email"
                                variant={'filled'}
                                size={'small'}
                                label={t('popup:intro-email-placeholder')}
                                className={classes.newsletterInputWrapper}
                                classes={{root: classes.newsletterInputRoot}}
                                error={!!newsletterEmail && !validateEmail(newsletterEmail)}
                                value={newsletterEmail}
                                onChange={event => setNewsletterEmail(event.target.value)}
                                inputProps={{ className: classes.newsletterInput }}
                                InputProps={{ disableUnderline: true, classes: { root: classes.newsletterInputBaseRoot } }}
                                InputLabelProps={{ className: classes.newsletterInputLabel }}
                            />
                        </div>
                        <Button className={classes.newsletterSignup} onClick={() => newsletterSubmit()}>
                            <>
                                {isDesktop ? t('popup:intro-subscribe') : <ChevronRight width={36} />}
                                {loading ? <CircularProgress size={14} color={'inherit'} style={{ marginLeft: 8 }}/> : ''}
                            </>
                        </Button>
                    </div>

                    {success ? <Alert severity={"success"} style={{ marginTop: 10 }}>{t('popup:newsletter-signup-success')}</Alert> : ''}
                </div>
            </div>
        </div>
    );
}
