import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import { getCMSSettings, getCMSSlot, getCMSConfig } from "tools/cmsBlockSettings";
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'tools/i18n';
import parsePrices from 'functions/parseSWPrices';
import formatPrice from 'functions/priceFormat';
import React, { useContext, useEffect, useState } from 'react';
import { TrackingContext } from '../../../providers/tracking';
import { SessionContext } from '../../../providers/session';
import FastDelivery from "../../../../public/images/icons/fast-delivery-thin.svg";
import InStock from "../../../../public/images/icons/i-lager-thin.svg";
import { getCategoryBreadcrumb, getVariants } from "../../../../functions/productOps";
import Like from "../../../../public/images/icons/heart-ultrathin.svg";

const useStyles = makeStyles(
	{
		wrapper: {
			alignItems: 'center',
			'&.reverse': {
				flexDirection: 'row-reverse'
			},
		},
		link: {
			color: 'inherit',
			textDecoration: 'none'
		},
		imageWrapper: {
			position: 'relative'
		},
		infoWrapper: {
			display: 'flex',
			flexDirection: 'column',
			'& .preTitle': {
				fontSize: 12,
				color: '#999',
				textTransform: 'uppercase',
				letterSpacing: 2,
				'.isDOTD &, .gw-section &': {
					color: '#fff'
				}
			},
			'& .title': {
				marginBottom: 10,
				lineHeight: 1.5,
				'.isDOTD &, .gw-section &': {
					color: '#fff'
				}
			},
			'& .description': {
				marginBottom: 10,
				fontSize: 16,
				fontWeight: 300,
				lineHeight: 1.5,
				color: '#666',
				'.isDOTD &, .gw-section &': {
					color: '#fff',
					fontWeight: 400,
				},
				'& a': {
					color: 'inherit'
				}
			},
			'& .specifications': {
				marginBottom: 10,
				fontSize: 14,
				fontWeight: 300,
				lineHeight: 1.7,
				color: '#666',
				'.isDOTD &, .gw-section &': {
					color: '#fff'
				}
			},
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
		prices: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontFamily: 'Chronicle, "Open Sans", sans-serif',
		},
		price: {
			fontSize: 28,
			color: '#666',
			'&:not(.old)': {
				...theme.typography.productPrice,
				fontFamily: 'inherit',
				fontSize: 28,
				color: '#333'
			},
			'&.special': {
				color: '#d92e3f',
				fontSize: 22
			},
			'&.old': {
				marginLeft: 15,
				fontSize: 'inherit',
				fontWeight: 400,
				color: '#000000',
				textDecoration: 'line-through'
			},
			'.isDOTD &, .gw-section &': {
				color: '#fff',
				'&:not(.old)': {
					fontSize: 36,
					fontFamily: 'Chronicle, serif',
					fontWeight: 400,
				},
				'&.old': {
					fontSize: 24,
					color: '#f'
				}
			}
		},
		labels: {
			position: 'absolute',
			top: 10,
			right: 10,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			gap: 10,
			[theme.breakpoints.up('md')]: {
				top: 10,
			}
		},
		discountBubble: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '6px 12px',
			fontSize: 14,
			fontWeight: 600,
			lineHeight: '22px',
			background: '#fff',
			color: '#000',
			pointerEvents: 'none',
			'.isDOTD &, .gw-section &': {
				position: 'relative',
				width: 64,
				height: 64,
				backgroundColor: 'transparent !important',
				'&:before': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 10,
					borderRadius: '50%',
					backgroundColor: '#121c2d',
				},
				'& span': {
					position: 'relative',
					zIndex: 10,
					fontFamily: 'Fenice, serif',
					fontSize: 22,
					color: '#fff'
				}
			}
		},
		marketingLabelWrapper: {
			display: 'flex',
			width: '100%',
			gap: 15
		},
		marketingLabel: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			alignSelf: 'flex-start',
			padding: '6px 12px',
			fontSize: 14,
			fontWeight: 500,
			color: '#fff',
			background: '#000',
			pointerEvents: 'none',
		},
		variants: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			gap: 10,
			marginTop: 15,
		},
		variantItem: {
			cursor: 'pointer',
			margin: '0 0 10px 0',
			padding: 10,
			fontSize: 12,
			lineHeight: '12px',
			borderRadius: 0,
			backgroundColor: 'white',
			border: '1px solid #888',
			'&.active': {
				fontWeight: 600,
				backgroundColor: 'white',
				border: '1.5px solid #444'
			},
			'&.image': {
				padding: 0,
				width: 65,
				'& > div': {
					display: 'block !important'
				}
			},
			'.isDOTD &, .gw-section &': {
				color: '#000',
				'&:not(.active)': {
					border: '1px solid #fff',
					backgroundColor: 'transparent',
					color: '#fff'
				},
				'&.active': {
					backgroundColor: '#fff !important',
					color: '#000',
					border: '1px solid #fff',
				},
				'&.image': {
					border: '1px solid rgba(255,255,255, 0.2)'
				}
			},
			'.hide-opt-1 &:nth-child(1)': {
				display: 'none'
			},
			'.hide-opt-2 &:nth-child(2)': {
				display: 'none'
			},
			'.hide-opt-3 &:nth-child(3)': {
				display: 'none'
			},
			'.hide-opt-4 &:nth-child(4)': {
				display: 'none'
			},
			'.hide-opt-5 &:nth-child(5)': {
				display: 'none'
			},
		},
		variantImage: {
			display: 'block',
			width: 55,
		},
		buyWrapper: {
			display: 'flex',
			alignItems: 'center',
			marginTop: 20,
			'.blackweek-dotd &': {
				flexDirection: 'column-reverse'
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse'
			}
		},
		buyButton: {
			...theme.button.purchase,
			padding: '20px 34px',
			minWidth: 0,
			flexGrow: 0,
			fontSize: 12,
			marginRight: 32,
			textTransform: 'uppercase',
			'&:hover': {
				...theme.button.purchaseHover
			},
			[theme.breakpoints.down('sm')]: {
				marginRight: 0,
				marginTop: 10,
				width: '100%'
			},
			'.isDOTD &, .gw-section &': {
				backgroundColor: '#ffffff !important',
				backgroundImage: 'linear-gradient(180deg, #ffffff, #ffffff) !important',
				color: '#000'
			},
			'&:before': {
				content: '""',
				height: 1,
				backgroundColor: 'white',
				display: 'block',
				position: 'absolute',
				top: 4,
				left: 0,
				right: 0,
				transition: 'all 0.25s ease'
			},
			'&:after': {
				content: '""',
				height: 1,
				backgroundColor: 'white',
				display: 'block',
				position: 'absolute',
				bottom: 4,
				left: 0,
				right: 0,
				transition: 'all 0.25s ease'
			},
		},
		soldOut: {
			color: '#ffffff !important',
			backgroundColor: '#000000',
			pointerEvents: 'none',
			'&:hover': {
				backgroundColor: '#01011d !important'
			},
			'.isDOTD &, .gw-section &': {
				backgroundColor: 'rgba(255,255,255,0.3)',
				backgroundImage: 'none'
			}
		},
		weddingListAdd: {
			maxHeight: 58,
			marginTop: 10,
			marginBottom: 10,
			backgroundColor: '#a39161',
			[theme.breakpoints.up('sm')]: {
				maxWidth: '75%'
			}
		},
		usps: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			marginTop: 5,
			[theme.breakpoints.down('sm')]: {
				alignItems: 'center',
				gap: '15px 10px'
			}
		},
		uspItem: {
			'& svg': {
				strokeWidth: 0.5
			},
			'.isDOTD &, .gw-section &': {
				color: '#fff'
			},
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				marginTop: 0,
				fontSize: 11.5,
				fontWeight: 300,
				'& svg': {
					flexShrink: 0,
					width: 15,
					marginRight: '10px !important',
					strokeWidth: 0.5
				}
			}
		},
		fastDelivery: {
			display: 'flex',
			flexDirection: 'column',
			gridColumn: '1/3',
			marginTop: 15,
			fontSize: 12,
			'.isDOTD &, .gw-section &': {
				color: '#fff'
			},
			'& svg': {
				marginRight: 15
			}
		},
		fastDeliveryText: {
			marginTop: 5,
			marginLeft: 33,
			marginBottom: 0,
			fontSize: 11,
			color: '#000',
			'.isDOTD &, .gw-section &': {
				color: '#fff'
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 25,
				fontSize: 11.5,
				fontWeight: 300
			}
		},
		freeShippingText: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			marginTop: 15,
			fontSize: 12,
			'& > svg': {
				marginRight: 15
			},
			'.isDOTD &, .gw-section &': {
				color: '#fff'
			},
			'&.wishlist': {
				cursor: 'pointer',
				marginBottom: 5,
				'& > svg': {
					fill: 'transparent',
				},
				'&.active > svg': {
					fill: '#000'
				}
			}
		},
		likeLoader: {
			position: 'absolute',
			top: 0,
			left: 0,
		},
	},
	{ name: 'NewportChosenProduct' }
);

export default function NewportChosenProduct({ block, slots, category, breadcrumbs }) {
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const { t } = useTranslation();
	const { actions } = useContext(TrackingContext);
	const session = useContext(SessionContext);
	const { config, currentLanguage, wishlistProducts, loggedIn, weddingList, actions: { addOrRemoveProductLike } } = session;
	const chosenProductData = getCMSSlot('chosenProduct', slots);
	const product = chosenProductData.data.product;
	const [selectedVariant, setSelectedVariant] = useState(product?.id);
	const prices = getPrices();
	const isDealOfTheDay = CMSSettings.class?.indexOf('-dotd') >= 0;
	const [weddingActive, setWeddingActive] = useState(false);
	const [wishlistActive, setWishlistActive] = useState(false);

	useEffect(() => {
		let currentProduct = product;

		if(!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		let inWishlist = wishlistProducts.filter((wishlistProduct) => wishlistProduct?.product_id === currentProduct.id);

		if(inWishlist.length > 0) {
			setWishlistActive(inWishlist[0]);
		} else {
			setWishlistActive(false);
		}
	}, [wishlistProducts]);

	useEffect(() => {
		if(weddingList?.items) {
			let inWeddingList = weddingList.items.filter((weddingListProduct) => {
				let cleanedProductId = weddingListProduct?.product_id;
				cleanedProductId = cleanedProductId.replaceAll('-', '');
				return cleanedProductId == product.id;
			});

			console.log(inWeddingList);

			if(inWeddingList.length > 0) {
				setWeddingActive(true);
			} else {
				setWeddingActive(false);
			}
		}
	}, [weddingList]);

	function isOriginalProduct() {
		return product && selectedVariant === product.id;
	}

	function getVariantProduct() {
		let variantProduct = false;
		product?.translated?.customFields?.variant_data.forEach(variant => {
			if (variant.id === selectedVariant) {
				variantProduct = variant;
			}
		});

		return variantProduct;
	}

	function getCurrentProduct() {
		if(!isOriginalProduct()) {
			return getVariantProduct();
		}

		return product;
	}

	function getPreTitle() {
		return chosenProductData.translated.config.preTitle.value;
	}

	function getProductName() {
		let variantProduct = product;
		const isOriginal = isOriginalProduct();

		if (!isOriginal) {
			variantProduct = getVariantProduct();
		}

		return !isOriginal ? variantProduct.translated.name : (chosenProductData.translated.config.title.value || product.translated.name);
	}

	function getProductDescription() {
		return chosenProductData.translated.config.description.value || product.translated.description;
	}

	function getProductSpecifications() {
		return chosenProductData.translated.config.specifications.value;
	}

	function getImage(product, altImage) {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		if (altImage) {
			return {
				src: altImage.url,
				alt: currentProduct.translated?.name,
				width: altImage.metaData.width,
				height: altImage.metaData.height,
				layout: 'responsive'
			};
		} else {
			if (product.cover) {
				return {
					src: isOriginalProduct() ? currentProduct.cover.media.url : currentProduct.cover.url,
					alt: currentProduct.translated?.name,
					width: isOriginalProduct()
						? currentProduct.cover.media.metaData.width
						: currentProduct.cover.metaData.width,
					height: isOriginalProduct()
						? currentProduct.cover.media.metaData.height
						: currentProduct.cover.metaData.height,
					layout: 'responsive'
				};
			} else {
				return {
					src: '/images/placeholder/product.jpg',
					alt: currentProduct.translated?.name,
					width: 550,
					height: 510
				};
			}
		}
	}

	function addToCart() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		session.actions.addToCart({ product: currentProduct.id, quantity: 1 });
		purchaseTracking(currentProduct);
	}

	function getProductUrl() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		if (currentProduct.seoUrls && currentProduct.seoUrls.length > 0) {
			return '/' + currentProduct.seoUrls[0].seoPathInfo;
		} else {
			return '/detail/' + currentProduct.id;
		}
	}

	function getPrices() {
		if (isOriginalProduct() && product) {
			let prices =
				product.calculatedPrices && product.calculatedPrices.length > 0
					? product.calculatedPrices[0]
					: product.calculatedPrice;
			return parsePrices(prices);
		} else if (product) {
			let variantProduct = getVariantProduct();
			return parsePrices(variantProduct.price);
		}
	}

	function nextShipmentText() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		let nextShipmentString = currentProduct.translated.customFields
			? currentProduct.translated.customFields.next_shipment_date
			: false;
		const nextShipmentDate = new Date(nextShipmentString);
		const now = new Date();

		if (
			!nextShipmentString ||
			nextShipmentDate <= now ||
			now.toLocaleDateString(currentLanguage.languageCode, { month: 'long' }) ===
				nextShipmentDate.toLocaleDateString(currentLanguage.languageCode, { month: 'long' })
		) {
			return '';
		}

		return (
			<>
				<div className={classes.nextShipmentText}>
					{t('estimated-shipping-date')}
					<span>{nextShipmentDate.toLocaleDateString(currentLanguage.languageCode, { month: 'long' })}</span>
				</div>
			</>
		);
	}

	function getStock() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		return currentProduct.availableStock;
	}

	function getUspStock(stock) {
		if (stock <= 0) {
			return '';
		}

		let product = getCurrentProduct();

		if (stock > 0 && product.deliveryTime) {
			return (
				<span className={[classes.freeShippingText, classes.uspItem].join(' ')}>
					<InStock width={18} />
					{product.deliveryTime?.translated?.name || product.deliveryTime.name}
				</span>
			);
		}

		return '';
	}

	function getMarketingLabels() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		if(!currentProduct.translated?.customFields?.marketing_label || typeof currentProduct.translated?.customFields?.marketing_label !== 'string') {
			return false;
		}

		return [{
			id: 'generic',
			label: currentProduct.translated?.customFields?.marketing_label
		}]
	}

	function purchaseTracking(currentProduct) {
		let categoryBreadcrumbs = getCategoryBreadcrumb(breadcrumbs, 1);

		if(categoryBreadcrumbs.length < 1) {
			categoryBreadcrumbs = [category.translated.name];
		}

		actions.addToCart({
			name: currentProduct.translated.name,
			id: currentProduct.productNumber,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: product.manufacturer ? product.manufacturer.translated.name : '',
			category: categoryBreadcrumbs,
			list: "CMS Chosen Product",
			variant: getVariants(product),
			quantity: 1
		});
	}

	function onClickTracking() {
		let currentProduct = product;
		if (!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		let categoryBreadcrumbs = getCategoryBreadcrumb(breadcrumbs, 1);

		if(categoryBreadcrumbs.length < 1) {
			categoryBreadcrumbs = [category.translated.name];
		}

		actions.productClick({
			name: currentProduct.translated.name,
			id: currentProduct.productNumber,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: product.manufacturer ? product.manufacturer.translated.name : '',
			category: categoryBreadcrumbs,
			list: "CMS Chosen Product",
			variant: getVariants(product),
			quantity: 1
		});
	}

	function selectVariant(variantId) {
		setSelectedVariant(variantId);
	}

	function isInWishlist() {
		let currentProduct = product;
		if(!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		return wishlistProducts.filter((wishlistProduct) => wishlistProduct?.product_id === currentProduct.id).length > 0;
	}

	async function handleProductLike(e) {
		e.preventDefault();
	    e.nativeEvent.preventDefault();

		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		let currentProduct = product;
		if(!isOriginalProduct()) {
			currentProduct = getVariantProduct();
		}

		let wishlistId = wishlistActive ? wishlistActive.wishlist_id : false;
		let productId = wishlistId ? wishlistActive.product_id : currentProduct.id;

		window.dispatchEvent(new CustomEvent('toggle-wishlist-product', { detail: { productId: productId } }));
	}

	async function handleWeddingList(e) {
		await addOrRemoveProductLike(product.id, weddingList.id, weddingActive ? 'remove' : 'add');
	}

	function renderProductVariants() {
		let splitGroups = product.translated?.customFields?.variant_split_group_ids || [];
		let variantData = [...product.translated?.customFields?.variant_data];
		let currentOptions = product?.options || [];

		let subVariantOption = [];
		if (product.options.length > 1 && splitGroups.length > 0) {
			currentOptions.forEach(option => {
				if (splitGroups.indexOf(option.groupId) >= 0) {
					subVariantOption.push(option.id);
				}
			});
		}

		return variantData
			.filter(variant => {
				if (!variant.available) {
					return false;
				}

				if (subVariantOption?.length > 0) {
					let sameSubvariant = true;

					subVariantOption.forEach(subVariant => {
						if (variant.optionIds?.indexOf(subVariant) < 0) {
							sameSubvariant = false;
						}
					});

					return sameSubvariant;
				} else {
					return true;
				}
			})
			.map(variant => {
				let optionLabel = '';
				let optionPosition = 0;
				let optionId = '';
				let optionImage = false;

				variant.options.forEach(option => {

					if (
						product.options.length < 2 ||
						(splitGroups.length > 0 &&
							variant.options.length > 1 &&
							splitGroups.indexOf(option.groupId) < 0)
					) {
						optionImage = splitGroups && splitGroups.indexOf(option.groupId) >= 0
							? (variant.cover
								? { ...variant.cover, alt: option.translated.name }
								: {
									url: '/images/placeholder/product.jpg',
									alt: option.translated.name,
									metaData: { width: 1020, height: 800 }
							})
							: false;
						optionLabel = option.translated.name;
						optionPosition = option.position;
						optionId = option.id;
					}
				});

				let isSelected = selectedVariant === variant.id;

				return (
					<div
						className={[
							classes.variantItem,
							'chosen-product-variant',
							isSelected ? 'active' : '',
							'vid-' + variant.id,
							optionImage ? 'image' : ''
						].join(' ')}
						onClick={() => {
							selectVariant(variant.id);
						}}
						style={{ order: variant.id === product.id ? -1 : optionPosition }}
					>
						{optionImage
							? <Image
								src={optionImage.url}
								width={optionImage.metaData.width}
								height={optionImage.metaData.height}
								className={classes.variantImage}
								unoptimized />
							: optionLabel}
					</div>
				);
			});
	}

	return product ? (
		<Grid
			spacing={4}
			className={[
				classes.wrapper,
				CMSSettings.class,
				getCMSConfig('direction', chosenProductData.config) ? 'reverse' : '',
				isDealOfTheDay ? 'isDOTD' : ''
			].join(' ')}
			style={{
				...CMSSettings.styles
			}}
			container
		>
			<Grid xs={12} md={6} className={[classes.imageWrapper, 'imageWrapper'].join(' ')} item>
				<Link href={getProductUrl()}>
					<a onClick={onClickTracking} style={{ position: 'relative', display: 'block' }}>
						<Image {...getImage(product, chosenProductData.data.media)} unoptimized />

						<div className={classes.labels}>
							{getMarketingLabels() && !isDealOfTheDay ? <div className={classes.marketingLabelWrapper}>{getMarketingLabels().map(({id, label}) => <span className={[classes.marketingLabel].join(' ')}>{label}</span>)}</div> : ''}

							{prices.discount ? <span className={[classes.discountBubble, 'discountBubble'].join(' ')}>
									<span>{Math.floor(prices.discount.percentage)}%</span>
								</span> : ''}
						</div>
					</a>
				</Link>
			</Grid>

			<Grid xs={12} md={6} className={[classes.infoWrapper, 'infoWrapper'].join(' ')} item>
				{getPreTitle() ? (
					<span className={['preTitle'].join(' ')}>{getPreTitle()}</span>
				) : (
					''
				)}

				<Link href={getProductUrl()}>
					<a
						className={[classes.link].join(' ')}
						onClick={onClickTracking}
					>
						<Typography variant="h2" className="title">
							{getProductName() + (isDealOfTheDay ? ' 70%' : '')}
						</Typography>
					</a>
				</Link>
				<div className="description" dangerouslySetInnerHTML={{ __html: getProductDescription() }} />
				{getProductSpecifications() ? (
					<div className="specifications">{getProductSpecifications()}</div>
				) : (
					''
				)}

				{chosenProductData?.config?.variantOptions?.value === true ? (
					<div className={classes.variants}>{renderProductVariants()}</div>
				) : (
					''
				)}

				<div className={classes.buyWrapper}>
					{product.available ? <>
						<Button classes={{ root: [classes.buyButton, 'buy-button'].join(' ') }} onClick={addToCart}>
							{t('buy')}
						</Button>
					</> : (
						<Button
							className={[classes.buyButton, classes.soldOut].join(' ')}
						>
							{t('sold-out')}
						</Button>
					)}

					<div className={classes.prices}>
						{prices.isOnSale ? (
							<>
								<span className={[classes.price, 'special'].join(' ')}>
									{formatPrice(prices.price)}
								</span>
								<span className={[classes.price, 'old'].join(' ')}>
									{formatPrice(prices.listPrice)}
								</span>
							</>
						) : (
							<span className={classes.price}>{formatPrice(prices.price)}</span>
						)}
					</div>
				</div>

				{weddingList ? <Button
						className={[
							classes.buyButton,
							classes.weddingListAdd
						].join(' ')}
						classes={{ disabled: classes.buyButton, label: classes.purchaseButtonLabel }}
						onClick={(e) => {
							handleWeddingList();
						}}>
					{ weddingActive ? t('remove-from-wedding-list') : t('add-to-wedding-list')}
				</Button> : ''}

				<div className={classes.usps}>
					{loggedIn ? <div
						className={[classes.freeShippingText, classes.uspItem, 'wishlist', isInWishlist() ? 'active' : ''].join(' ')}
						onClick={(event) => handleProductLike(event)}
					>
						<Like width={20} height={20} alt={t('buy-short')}/>
						<span>{ isInWishlist() ? t('is-in-wishlist') : t('add-to-wishlist')}</span>
					</div> : ''}

					{getCurrentProduct().translated.customFields.fast_delivery_available && config.language.locale === 'sv' ? (
						<div className={[classes.fastDelivery, classes.uspItem].join(' ')}>
							<span>
								<FastDelivery width={18} style={{ verticalAlign: 'text-top' }} />
								Fast Delivery
							</span>
							<p className={classes.fastDeliveryText}>I lager och skickas inom 24h under helgfria vardagar</p>
						</div>
					) : (
						''
					)}

					{!nextShipmentText() && (!getCurrentProduct().translated.customFields.fast_delivery_available || config.language.locale !== 'sv') ? getUspStock(getStock()) : ''}
				</div>
			</Grid>
		</Grid>
	) : (
		''
	);
}
